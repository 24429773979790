import type { Component } from 'vue';

type TemplateMap = {
  [contentAlias: string]: Component | undefined | null;
};

const cachedTemplatesMap: TemplateMap = {};

/**
 * Dynamically retrieves a template to load based on a provided content type alias.
 * If a template has already been loaded, the cached template function will be returned.
 *
 * @note
 * All templates must be placed in `@/components/Templates` and each file must be in `PascalCase` for this to work.
 *
 * @param contentTypeAlias Alias of content to load template for.
 * @returns Function that returns a Promise that will import the template.
 */
const templateFactory = (contentTypeAlias: string) => {
  if (cachedTemplatesMap[contentTypeAlias] != null)
    return () => Promise.resolve(cachedTemplatesMap[contentTypeAlias]!);

  return () => importTemplateAsync(contentTypeAlias);
};

/**
 * Imports a template based on the provided content type alias.
 *
 * @param contentTypeAlias Alias of content to load template for.
 * @returns Promise with imported template data.
 */
const importTemplateAsync = async (contentTypeAlias: string) => {
  const templateName =
    contentTypeAlias[0].toUpperCase() + contentTypeAlias.substring(1);
  try {
    const module: ComponentModule = await import(
      `./${templateName}Template.vue`
    );
    return (cachedTemplatesMap[contentTypeAlias] = module.default!);
  } catch (err: unknown) {
    const msg = `Failed to import template "${templateName}Template.vue"\n-----\n`;
    if (err instanceof Error) {
      console.error(msg, err);
      throw err;
    } else {
      throw new Error(msg, {
        cause: err
      });
    }
  }
};

export default templateFactory;
