// #region HELPERS
export interface Dictionary<T> {
  [key: string]: T;
}
export type LocalizableString = string | Dictionary<string> | undefined;

export enum Lang {
  en = 'en',
  es = 'es'
}

export interface Options {
  label: LocalizableString;
  value: string;
}
// #endregion HELPERS

// #region BASE INTERFACES
export interface BaseField<T> {
  data: T;
  id: string;
  errors?: Dictionary<LocalizableString>;
  extraCondition?: string | ((form: Form) => boolean);
  newPassword?: string;
  label?: LocalizableString;
  placeholder?: LocalizableString;
  note?: LocalizableString;
  noteAbove?: LocalizableString;
  mask?: string;
  autofill?: boolean;
  pinLabel?: boolean;
  justifyLabel?: boolean;
  related?: string[];
  size?: string;
  inline?: boolean;
}
// #endregion BASE INTERFACES

// #region FIELD INTERFACES
export interface TextFieldType extends BaseField<string> {
  type?:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'tel'
    | 'text'
    | 'number'
    | 'time'
    | 'url'
    | 'week'
    | 'password';
  minLength?: number;
  maxLength?: number;
}

export interface TextAreaType extends BaseField<string> {
  rows: number;
  minLength?: number;
  maxLength?: number;
}

export interface BooleanFieldType extends BaseField<boolean> {}

export interface SelectFieldType extends BaseField<string> {
  options: Options[];
  noLabelStyling?: boolean;
}

export interface RadioFieldType extends BaseField<string> {
  options: Options[];
  inline?: boolean;
}

export interface CheckFieldType extends BaseField<string[]> {
  options: Options[];
  inline?: boolean;
}

export interface DatePicker extends BaseField<string> {
  minDate?: string;
  maxDate?: string;
}
// #endregion FIELD INTERFACES

// #region FILE UPLOAD FIELDS (SINGLE AND MULTIPLE) INTERFACES
export interface CustomFileType {
  name: string;
  data: Blob;
  mimeType?: string;
}

interface OmittedFileProperties {
  placeholder?: LocalizableString;
}
export interface FileSingleFieldType
  extends Omit<BaseField<CustomFileType | null>, keyof OmittedFileProperties> {
  /** must be formated appropriately for the html 'accept' attribute */
  fileTypesAccepted: string;
  /** an array of strings of accepted file extensions */
  fileTypesArray: string[];
  hasFileSizeLimit: boolean;
  maximumFileSizeInMB: number;
  fileNamePrefix?: string;
  subLabel?: LocalizableString;
  disabled?: boolean;
}

interface OmittedFileProperties2 {
  data: CustomFileType | null;
}

export interface FilesMultipleFieldType
  extends Omit<FileSingleFieldType, keyof OmittedFileProperties2> {
  data: CustomFileType[];
  filesListLabel?: LocalizableString;
}

// #endregion FILE UPLOAD FIELDS (SINGLE AND MULTIPLE) INTERFACES

export interface ErrorBagFieldType extends BaseField<string[]> {}

// #region FORM INTERFACE

export type FormField =
  | BooleanFieldType
  | TextFieldType
  | SelectFieldType
  | RadioFieldType
  | CheckFieldType
  | DatePicker
  | FileSingleFieldType
  | FilesMultipleFieldType
  | TextAreaType
  | ErrorBagFieldType;

export interface Form {
  [key: string]: FormField;
}

export type ExtractFormDataTypes<T extends Form> = {
  [K in keyof T]: T[K] extends BaseField<infer D> ? D : never;
};
// #endregion FORM INTERFACE
