import { SESSION_LASTURL } from '@/shared/constants';
import type { RouteLocationNormalized } from 'vue-router';

/** Tracks the last page visited during a navigation event */
export const trackHistory = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const lastUrl = sessionStorage.getItem(SESSION_LASTURL);
  if (lastUrl == null || lastUrl !== from.fullPath) {
    sessionStorage.setItem(SESSION_LASTURL, from.fullPath);
  }
};
