import { useProfileStore } from '@/stores/profile';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

/**
 * Navigation guard that will automatically check if a member is logged in.
 * If they are, it will retrieve their profile data and redirect the user to their perspective dashboard.
 */
export const profileGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const profileStore = useProfileStore();

  // Check if member is logged in
  const isLoggedIn = await profileStore.checkLoginAsync();
  if (!isLoggedIn) return next();

  // Update profile store with latest profile data since we know the member is logged in
  await profileStore.getProfileAsync();

  return next();
};
