import type { App, Plugin } from 'vue';

const errorHandler: Plugin = {
  install(app: App) {
    app.config.errorHandler = err =>
      console.error('[Global Unhandled Error]', err);
  }
};

export default errorHandler;
