/**
 * The name of the `window` that Umbraco uses for preview mode.
 *
 * @remarks
 * When a user is viewing the site in preview mode, it is rendered within an `<iframe>` element.
 * The name of the window that this element resides in is the value that this constant represents.
 */
export const UMBRACO_PREVIEW_IFRAME_NAME = 'umbpreview';

/**
 * Name of the cookie Umbraco uses to determine if preview mode is enabled.
 * This cookie does **not** determine whether preview mode is enabled for the entire site.
 *
 * @remarks
 * The initial preview window that Umbraco opens renderer the site within an `<iframe>` element.
 * When this action occurs, the cookie that this constant represents is set.
 */
export const UMBRACO_PREVIEW_COOKIE = 'UMB_PREVIEW';

/**
 * Name of the cookie Umbraco uses to determine if the entire website should be viewed in preview mode.
 *
 * @remarks
 * When preview mode is enabled for the entire site, Umbraco sets a cookie.
 * The value of this cookie is what this constant represents.
 */
export const UMBRACO_WEBSITE_PREVIEW_COOKIE = 'UMB-WEBSITE-PREVIEW-ACCEPT';
