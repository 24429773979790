<template>
  <footer id="footer" class="focused">
    <div class="box box1600">
      <p class="foot-note">
        &copy;{{ currentYear }} Sonora Quest Laboratories, All Rights
        Reserved.<br />
        Digital Transformation by I-ology.
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { format } from 'date-fns';
const currentYear = format(new Date(), 'yyyy');

//#region DYNAMIC BODY SIZE
onMounted(() => {
  const footer = document.getElementById('footer');
  const updateCustomProperties = () => {
    if (footer) {
      document.documentElement.style.setProperty(
        '--footer_height',
        `${footer.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--footer_height', `0px`);
    }
  };
  updateCustomProperties();
  const resizeObserver = new ResizeObserver(updateCustomProperties);
  if (footer) resizeObserver.observe(footer);
  onUnmounted(() => {
    if (footer) resizeObserver.unobserve(footer);
    resizeObserver.disconnect();
  });
});
//#endregion DYNAMIC BODY SIZE
</script>
