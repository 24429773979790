<template>
  <header id="header" class="focused">
    <div class="box box1600">
      <router-link v-if="showLink" id="logo" to="/">
        <img src="/assets/logo.svg" alt="Sonora Quest Laboritory" />
      </router-link>
      <img
        v-else
        src="/assets/logo.svg"
        id="logo"
        alt="Sonora Quest Laboritory"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
//#region GENERAL IMPORTS
import { ref, onMounted, onUnmounted } from 'vue';
//#endregion GENERAL IMPORTS

const showLink = ref(false);

//#region DYNAMIC BODY SIZE
onMounted(() => {
  const important = document.getElementById('important');
  const header = document.getElementById('header');
  const updateCustomProperties = () => {
    if (important) {
      document.documentElement.style.setProperty(
        '--important_height',
        `${important.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--important_height', `0px`);
    }
    if (header) {
      document.documentElement.style.setProperty(
        '--header_height',
        `${header.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--header_height', `0px`);
    }
  };
  updateCustomProperties();
  const resizeObserver = new ResizeObserver(updateCustomProperties);
  if (important) resizeObserver.observe(important);
  if (header) resizeObserver.observe(header);
  onUnmounted(() => {
    if (important) resizeObserver.unobserve(important);
    if (header) resizeObserver.unobserve(header);
    resizeObserver.disconnect();
  });
});
//#endregion DYNAMIC BODY SIZE
</script>
