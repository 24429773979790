import type { Directive } from 'vue';
import type { Router } from 'vue-router';

/**
 * Creates a directive that will replace default navigation functionality with Vue Router.
 * This directive was designed to be used when `v-html` is also present.
 *
 * @param router Instance of Vue Router to use for overriding default navigation functionality.
 * @returns
 */
export const createRouterifyAnchors = (
  router: Router
): Directive<HTMLElement> => {
  const eventListeners = new Map<HTMLAnchorElement, (e: Event) => void>();

  /**
   * Anchor element event callback that will prevent default functionality, remove the event listener itself, and then navigate with Vue Router.
   *
   * @param e The event.
   */
  const navigateWithRouter = (e: Event, anchor: HTMLAnchorElement) => {
    const href = anchor.getAttribute('href');
    if (href && href.startsWith('#')) {
      return;
    }
    e.preventDefault();

    // Remove the click listener so we know this code will only run a maximum of one time
    if (eventListeners.has(anchor)) {
      document.removeEventListener(e.type, eventListeners.get(anchor)!);
      eventListeners.delete(anchor);
    }

    // Get the base URL by combining the protocol and host
    // If the anchor's href contain's the base URL (which is almost guaranteed), remove it via substring
    // This is required because using absolute URLs with router.push() does not end well
    const baseUrl = window.location.protocol + '//' + window.location.host;
    const index = anchor.href.indexOf(baseUrl);
    const url =
      index === -1 ? anchor.href : anchor.href.substring(baseUrl.length);
    router.push(!url.startsWith('/') ? '/' + url : url);
  };

  /**
   * Queries a provided `HTMLElement` for all anchor (`<a>`) elements that are linking to an internal page.
   * For each of these anchors, the default navigation functionality is replaced with Vue Router's navigation.
   *
   * @param el Element to check.
   */
  const routerifyAnchors = (el: HTMLElement) => {
    // Query for all anchor elements in HTML
    const anchorElements =
      el.tagName === 'A'
        ? [el as HTMLAnchorElement].concat(Array.from(el.querySelectorAll('a')))
        : [...Array.from(el.querySelectorAll('a'))];
    if (anchorElements.length == 0) return;

    // Store information about current URL because it will be used later
    const { protocol, host } = window.location;
    const protocolAndHost = `${protocol}//${host}`;
    const protocolAndHostLength = protocolAndHost.length;

    // Loop through each anchor element and filter for elements that have an href to an internal page
    anchorElements.forEach(a => {
      const href = a.href;

      // If the anchor's href is to an external site, ignore
      if (!href.startsWith(protocolAndHost)) return;

      // We only need to pay attention to links that start with https or http. Relative links are fine too (i.e. no protocol required)
      // For protocols such as mailto:, file:, etc., we want to keep default functionality
      const hrefProtocolIndex = href.indexOf(':');
      if (hrefProtocolIndex !== -1) {
        const hrefProtocol = href.substring(0, hrefProtocolIndex);
        if (hrefProtocol !== 'http' && hrefProtocol !== 'https') return;
      }

      // If the anchor's href is pointing to an internal URL that should be ignored, ignore
      if (
        href[protocolAndHostLength] === '/' &&
        (href.startsWith('api', protocolAndHostLength + 1) ||
          href.startsWith('media', protocolAndHostLength + 1))
      )
        return;

      // If the anchor is configured to open in a new tab, ignore
      if (a.target === '_blank') return;

      // Add event listener
      const listener = (e: Event) => navigateWithRouter(e, a);
      eventListeners.set(a, listener);
      a.addEventListener('click', listener);
    });
  };

  return {
    mounted: routerifyAnchors,
    updated: routerifyAnchors,
    beforeUpdate: (el: HTMLElement) => {
      // Query for all anchor elements in HTML
      const anchorElements = el.querySelectorAll('a') ?? [];
      if (anchorElements.length == 0) return;

      // Remove all event listeners
      anchorElements.forEach(a => {
        if (eventListeners.has(a)) {
          a.removeEventListener('click', eventListeners.get(a)!);
          eventListeners.delete(a);
        }
      });
    }
  };
};
