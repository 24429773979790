/**
 * Returns the value of the cookie with the specified name.
 *
 * @param name - The name of the cookie to retrieve.
 * @returns The value of the cookie, or undefined if the cookie does not exist.
 */
export const getCookie = (name: string): string | undefined => {
  const cookieMap = document.cookie
    .split(';')
    .reduce<{ [cookie: string]: string | undefined }>((obj, cookie) => {
      const split = cookie.trim().split('=');
      if (split.length == 0) return obj;

      return {
        ...obj,
        [split[0]]: split[1]
      };
    }, {});

  return cookieMap[name];
};

/**
 * Represents the options that can be passed when setting a cookie.
 */
export type CookieSetOptions = {
  /**
   * The number of seconds until the cookie expires.
   */
  expires?: number;
  /**
   * The domain that the cookie is valid for.
   */
  domain?: string;
  /**
   * The path that the cookie is valid for.
   */
  path?: string;
  /**
   * Whether the cookie should only be sent over HTTPS.
   */
  secure?: boolean;
};

/**
 * Sets a cookie with the given name and value, along with optional cookie settings.
 *
 * @param name - The name of the cookie.
 * @param value - The value of the cookie.
 * @param options - Optional settings for the cookie.
 */
export const setCookie = (
  name: string,
  value: string,
  options?: CookieSetOptions
): void => {
  options = options ?? {};
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options.expires != null) {
    const date = new Date();
    date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
    cookieString += `; expires=${date.toUTCString()}`;
  }

  if (options.domain != null) {
    cookieString += `; domain=${options.domain}`;
  }

  if (options.path != null) {
    cookieString += `; path=${options.path}`;
  }

  if (options.secure === true) {
    cookieString += '; Secure';
  }

  document.cookie = cookieString;
};

/**
 * Deletes a cookie with the given name.
 *
 * @param name - The name of the cookie to delete.
 * @param options - Optional cookie settings.
 */
export const deleteCookie = (
  name: string,
  options?: Omit<CookieSetOptions, 'expires'>
): void => {
  options = options ?? {};
  setCookie(name, '', {
    expires: -10, // Make cookie expire 10 days ago, which will delete it
    domain: options?.domain,
    path: options?.path,
    secure: options?.secure
  });
};
