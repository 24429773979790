<template>
  <svg
    version="1.1"
    id="sql-loading-svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <path
      :fill="computedColor"
      class="logo"
      d="M32,12.9c-0.9-0.5-1.9-0.9-2.9-1.2l-0.2-0.2V0h3.1v8.4l5.8-5.8l0,0l5.9,5.9l0,0l0,0l-7.9,7.9 c-0.7-0.8-1.4-1.6-2.2-2.2l5.7-5.7L37.9,7L32,12.9z M50,18.2h-8.2l5.9-5.9l-2.2-2.2l-8.1,8.1v0.2c0.5,0.9,0.9,1.9,1.1,2.9h8.4v2.1 h-8c0.1,0.5,0.1,1,0.1,1.5c0,0.5,0,1-0.1,1.5H50v0l0,0V18.2z M43,37.9l-1.4,1.4l-5.7-5.7c-0.6,0.8-1.4,1.5-2.2,2.2l7.9,7.9l0,0l0,0 l5.9-5.9l0,0L41.7,32H50v-3.1H38.5l-0.2,0.2c-0.3,1-0.7,2-1.3,2.9L43,37.9z M28.6,46.8h-2.1v-8c-0.5,0.1-1,0.1-1.6,0.1 c-0.5,0-1,0-1.6-0.1v11.1h8.3v-8.2l5.9,5.9l2.2-2.2l-8.1-8.1h-0.2c-0.9,0.5-1.9,0.9-2.9,1.1V46.8z M12.1,43l-1.4-1.4l5.7-5.7 c-0.8-0.6-1.6-1.4-2.2-2.2l-7.9,7.9l0,0l0,0l5.9,5.9l0,0l5.8-5.8V50h3.1V38.5l-0.2-0.2c-1-0.3-2-0.7-2.9-1.2L12.1,43z M3.1,28.6 v-2.1h8C11.1,26,11,25.5,11,25c0-0.5,0-1,0.1-1.5H0v0l0,0v8.3h8.2l-5.9,5.9l2.2,2.2l8.1-8.1v-0.2c-0.5-0.9-0.9-1.9-1.1-2.9 C11.5,28.6,3.1,28.6,3.1,28.6z M7,12.1l1.4-1.4l5.7,5.7c0.7-0.8,1.4-1.6,2.2-2.2L8.4,6.2h0l-5.9,5.9l0,0l5.8,5.8H0V21h11.5l0.2-0.2 c0.3-1,0.7-2,1.2-2.9L7,12.1z M21.4,3.1h2.1v8c0.5-0.1,1-0.1,1.6-0.1c0.5,0,1,0,1.6,0.1V0h-8.3v8.2l-5.9-5.9l-2.2,2.2l8.1,8.1h0.2 c0.9-0.5,1.9-0.9,2.9-1.1L21.4,3.1L21.4,3.1z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="1.5s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
}>();

const computedColor = computed(() => props.color ?? '#832a36');
</script>
