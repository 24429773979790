import { ROUTES } from '@/shared/constants';
import { useContentCache } from '@/stores/content-cache';
import { useProfileStore } from '@/stores/profile';
import type { MemberTypeDropdown } from '@/types';
import type { MemberType } from '@/types/profile';
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationPathRaw
} from 'vue-router';

export interface AuthGuardData {
  memberType?: MemberTypeDropdown;
  returnUrl?: string;
}

/**
 * Navigation guard that will automatically add proper authorization protections to routes if they are configured for it.
 * In order for this guard to work, a page must have authorization configured in its Umbraco content.
 * This guard also must run after `contentFetchGuard`.
 */
export const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  // If already on unauthorized page, check if user still needs to be there
  if (to.path === ROUTES.UNAUTHORIZED) {
    // Decode data from URL
    const data64 = decodeURIComponent((to.query.data as string) ?? '');
    if (data64.length <= 0) return next();
    const data = window.base64DecodeJson<AuthGuardData>(data64);
    if (data == null) return next();

    // If user is still unauthorized, keep them here
    if (!isAuthorized(true, data.memberType?.value)) return next();

    // If we got this far, then the user is no longer unauthorized. Redirect them away
    return next(data.returnUrl ?? '/');
  }

  // Get page data and authorization settings
  const contentCache = useContentCache();
  const { pageData } = contentCache;
  if (pageData == null) return next();

  // Check if user is authorized
  const authSettings = pageData.authorization;
  const { requireLogin, memberType } = authSettings;
  if (isAuthorized(requireLogin, memberType?.value)) return next();

  // If user is logged out and we know the member type required, automatically redirect to login screen
  const profileStore = useProfileStore();
  const { isLoggedIn } = profileStore;
  if (!isLoggedIn && memberType != null) {
    const loginUrl = profileStore.generateLoginUrl(
      memberType.value,
      to.fullPath
    );
    window.location.href = loginUrl;
  } else {
    // Otherwise, show unauthorized route
    return next(
      buildUnauthorizedLocation({
        memberType,
        returnUrl: to.fullPath
      })
    );
  }
};

/**
 * Checks if the user is authorized. If they are, return true.
 * If they are not, return false.
 */
export const isAuthorized = (
  requiresLogin: boolean,
  memberType?: MemberType
) => {
  // If page doesn't even require login, return true
  if (!requiresLogin) return true;

  // If page requires login and the user is already logged in (and authorized), return true
  const profileStore = useProfileStore();
  const { profile, isLoggedIn } = profileStore;
  if (
    isLoggedIn &&
    profile?.memberType != null &&
    (memberType == null || profile.memberType === memberType)
  )
    return true;

  // If all checks above have failed, then the user is NOT authorized, return false
  return false;
};

/** Builds a route path that can be used for navigating to the Unauthorized route */
export const buildUnauthorizedLocation = (
  data: AuthGuardData
): RouteLocationPathRaw => ({
  path: ROUTES.UNAUTHORIZED,
  query: {
    data: encodeURIComponent(window.base64Encode(data))
  }
});
