import { useContentCache } from '@/stores/content-cache';
import type { PageData } from '@/types';
import { storeToRefs } from 'pinia';
import { isBackofficePreview, isWebsitePreview } from '../helpers';

/**
 * Navigation hook that overrides the default functionality of Umbraco's Preview badge UI.
 *
 * @remarks
 * By default, Umbraco's Preview UI has links that depend on content being served in an MVC fashion.
 * This navigation hook automatically updates these links so that they are up-to-date with the current route.
 */
export const websitePreviewHook = async () => {
  const contentCache = useContentCache();
  const { pageData } = storeToRefs(contentCache);
  if (pageData.value == null) return;

  // We need to override the UI for both the backoffice and website preview modes that Umbraco offers
  if (isBackofficePreview()) overrideBackofficePreview(pageData.value);
  else if (isWebsitePreview()) overrideWebsitePreview(pageData.value);
};

const overrideBackofficePreview = (pageData: PageData) => {
  // Get the global instance of Angular that Umbraco uses for the backoffice
  const backofficeWindow = window.parent;
  const backofficeAngular = <ng.IAngularStatic>(
    (backofficeWindow as any).angular // eslint-disable-line
  );

  const canvasDesignerPanel = backofficeWindow.document.getElementById(
    'canvasdesignerPanel'
  );
  if (canvasDesignerPanel == null) return;

  // Get the $scope of Umbraco's built-in preview controller, which is powered by Angular
  type PreviewControllerScope = ng.IScope & {
    pageId: string;
  };
  const $scope = backofficeAngular
    .element(canvasDesignerPanel)
    .scope() as PreviewControllerScope;

  // By updating the scope's pageId, Umbraco's preview Angular controller will now open the correct URLs
  $scope.pageId = pageData.metadata.id;
};

const overrideWebsitePreview = (pageData: PageData) => {
  const previewBadgeContainer = document.getElementById('umbracoPreviewBadge');
  if (previewBadgeContainer == null) return;

  const anchors = [
    ...previewBadgeContainer.getElementsByClassName('umbraco-preview-badge__a')
  ];

  // Try to override the anchor element that opens up the backoffice preview window
  const openAnchor = <HTMLAnchorElement | undefined>(
    anchors.find(a => a.tagName === 'A' && a.classList.contains('open'))
  );
  if (openAnchor != null) {
    const updater = getAnchorParamUpdater(openAnchor);
    updater?.call(null, 'id', pageData.metadata.id);
  }

  // Try to override the anchor element that ends preview mode
  const endAnchor = <HTMLAnchorElement | undefined>(
    anchors.find(a => a.tagName === 'A' && a.classList.contains('end'))
  );
  if (endAnchor != null) {
    const updater = getAnchorParamUpdater(endAnchor);
    updater?.call(null, 'redir', window.location.pathname);
  }
};

const getAnchorParamUpdater = (anchor: HTMLAnchorElement) => {
  const index = anchor.href.indexOf('?');
  if (index !== -1) {
    const query = anchor.href.substring(index);
    const params = new URLSearchParams(query);
    return (param: string, value: string) => {
      params.set(param, value);
      anchor.href = anchor.href.substring(0, index) + '?' + params.toString();
    };
  }
  return undefined;
};
