<template>
  <component :is="activeTemplate"></component>
</template>

<script setup lang="ts">
import templateFactory from '@/components/Templates/template-factory';
import { ROUTES } from '@/shared/constants';
import { useContentCache } from '@/stores/content-cache';
import { storeToRefs } from 'pinia';
import { type Component, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

// Purposefully do not use "usePageData" because that can throw an exception
const contentCache = useContentCache();
const { pageData } = storeToRefs(contentCache);

// Store the template that is being actively rendered
// Define function that will use the template factory to load to set a new active template
const router = useRouter();
const activeTemplate = ref<Component | undefined>(undefined);
const fetchTemplateComponent = (customAlias?: string) => {
  const alias = customAlias ?? pageData.value?.alias;
  if (alias != null) {
    templateFactory(alias)()
      .then(c => (activeTemplate.value = c))
      .catch((err: unknown) => {
        console.error(
          `[PageTemplateRenderer] Error importing template with alias '${alias}'\n`,
          err
        );
        router.push(ROUTES.NOT_FOUND);
      });
  } else {
    console.warn(
      '[PageTemplateRenderer] Ignore template import because the alias is null\n'
    );
    router.push(ROUTES.NOT_FOUND);
  }
};

// When component initially loads, get the template that should be loaded based on the current page data
// Then, setup a watcher that will update the active template when the page data changes
watch(pageData, fetchTemplateComponent.bind(null, undefined), {
  immediate: true
});
</script>
