import type { PageData } from '@/types';
import apiClient from '../client';

export class ContentApi {
  private readonly _client = apiClient;

  /**
   * Retrieve content by route.
   *
   * @param route Route of content to retrieve
   * @returns API response representing content.
   */
  getByRoute(route: string) {
    return this._client.get<PageData | undefined>(
      '/api/content/?route=' + encodeURIComponent(route)
    );
  }

  /**
   * Retrive content by id. The integer or GUID id can be used.
   *
   * @param id Id of content to retrieve.
   * @returns API response representing content.
   */
  getById(id: string | number) {
    return this._client.get<PageData | undefined>('/api/content/' + id);
  }
}

let contentApi: ContentApi | undefined = undefined;
export default contentApi ??= new ContentApi();
