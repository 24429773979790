import { SQL_TEMPORARY_CONTENT_COOKIE } from '@/shared/constants-cookies';
import {
  UMBRACO_PREVIEW_COOKIE,
  UMBRACO_PREVIEW_IFRAME_NAME,
  UMBRACO_WEBSITE_PREVIEW_COOKIE
} from '@/shared/constants-umbraco';
import { deleteCookie, getCookie } from '@/shared/cookie-helper';

/**
 * Determines if the site is being previewed from the backoffice.
 */
export const isBackofficePreview = () => {
  return (
    getCookie(UMBRACO_PREVIEW_COOKIE) != null &&
    ((window === window.parent &&
      window.name === UMBRACO_PREVIEW_IFRAME_NAME) ||
      window.parent.name === UMBRACO_PREVIEW_IFRAME_NAME ||
      window.parent.location.href.includes('/umbraco/preview'))
  );
};

/**
 * Determines if website preview mode is enabled.
 */
export const isWebsitePreview = () => {
  return (
    getCookie(UMBRACO_PREVIEW_COOKIE) != null &&
    !isBackofficePreview() &&
    getCookie(UMBRACO_WEBSITE_PREVIEW_COOKIE) === 'true'
  );
};

/**
 * Deletes the temporary cookie that is used for fetching content by id.
 */
export const deleteContentCookie = () => {
  deleteCookie(SQL_TEMPORARY_CONTENT_COOKIE, {
    domain: window.location.hostname, // Cookie will always be created from this domain
    secure: true, // Cookie is set to secure from the backend
    path: '/' // This is the path the backend specifies
  });
};
