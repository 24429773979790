import type { PageData } from '@/types';
import { defineStore, storeToRefs } from 'pinia';
import { type Ref, ref } from 'vue';

type CachedPageData = { [path: string]: PageData };

/**
 * Use the store designed for storing page content.
 *
 * Here you can retrieved cached content or access the content for the active page.
 */
export const useContentCache = defineStore('contentCache', () => {
  const cachedPageData = ref<CachedPageData>({});
  const pageData = ref<PageData | null>(null);

  /**
   * Add or override an existing set of cached `PageData`.
   *
   * @param data Data to cache.
   */
  const addCachedData = (data: PageData) => {
    const path = data.metadata.relativeUrl;
    cachedPageData.value[path] = data;
  };
  /**
   * Retrieve an instance of `PageData` from the cache.
   *
   * @param path Relative URL of cached content.
   * @returns Instance of cached `PageData`.
   */
  const getCachedData = (path: string) => cachedPageData.value[path];
  /**
   * Set the active page data.
   *
   * @param data Data to set.
   */
  const setPageData = (data: PageData) => {
    addCachedData(data);
    pageData.value = data;
  };

  return {
    cachedPageData,
    pageData,
    addCachedData,
    getCachedData,
    setPageData
  };
});

/**
 * Get the active page data from the content data.
 *
 * @returns Reference to active page data.
 */
export const usePageData = <C = Record<string, any>>() => {
  const contentCache = storeToRefs(useContentCache());
  if (contentCache.pageData.value == null) {
    throw new Error('Cannot access null page data from content cache');
  }
  return <Ref<PageData<C>>>contentCache.pageData;
};
