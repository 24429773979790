import { defineStore } from 'pinia';
import { type Component, markRaw, reactive, computed } from 'vue';

export type Modal<S extends {} = {}> = {
  isOpen: boolean;
  view: Component;
  viewProps?: Object;
  title?: string;
  actions?: ModalAction[];
  customState: Record<string, any>; // eslint-disable-line
  closeCallback?: (state: S) => void;
};

export type ModalAction = {
  label: string;
  type?: 'button' | 'link';
  color?: 'info' | 'success' | 'error';
  outline?: boolean;
  loading?:
    | boolean
    | (<S extends Modal>(action: ModalAction, state: S) => boolean);
  callback?: <S extends Modal>(action: ModalAction, state: S) => void;
};

export type OpenModalRenderOptions<C extends Component, P extends {}> = {
  component: C;
  props?: P;
};

export type OpenModalViewOptions = {
  title?: string;
  actions?: ModalAction[];
  closeCallback?: <S extends {}>(state: S) => void;
};

export const useModal = defineStore('modal', () => {
  const internalState = reactive<Modal>({
    isOpen: false,
    view: {},
    viewProps: undefined,
    title: undefined,
    actions: [],
    closeCallback: undefined,
    customState: {}
  });
  const state = computed(() => internalState);

  function open<C extends Component, P extends {}, S extends {}>(
    view: C | OpenModalRenderOptions<C, P>,
    viewOptions?: OpenModalViewOptions,
    customState?: S
  ): void {
    internalState.isOpen = true;

    if (Object.hasOwn(view, 'component')) {
      const renderOptions = <OpenModalRenderOptions<C, P>>view;
      internalState.view = markRaw(renderOptions.component);
      internalState.viewProps =
        renderOptions?.props != null ? markRaw(renderOptions.props) : undefined;
    } else {
      internalState.view = markRaw((<Component>view) as C);
      internalState.viewProps = undefined;
    }

    internalState.title = viewOptions?.title;
    internalState.actions = viewOptions?.actions ?? [];
    internalState.customState = customState ?? {};
    internalState.closeCallback = viewOptions?.closeCallback;
  }

  const close = () => {
    internalState.isOpen = false;
    internalState.view = {};
    internalState.viewProps = undefined;
    internalState.title = undefined;
    internalState.actions = [];
    internalState.closeCallback?.(internalState.customState);
  };

  return { state, open, close };
});

export default useModal;
