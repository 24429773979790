<template>
  <a
    v-if="isLinkOut"
    @click="mobiNavClose()"
    :href="props.link.url"
    :target="props.link.target"
    :class="props.link.customClasses"
    >{{ props.link.title }}</a
  >
  <a
    v-else
    @click.prevent="letsGo()"
    href="#"
    :class="props.link.customClasses"
    >{{ props.link.title }}</a
  >
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
import type { NavigationItemData } from '@/types/content/content-api';

const emit = defineEmits<{
  (event: 'mobiNavClose'): void;
}>();

const props = defineProps<{
  link: NavigationItemData;
}>();

const mobiNavClose = () => {
  emit('mobiNavClose');
};

const letsGo = () => {
  router.push(props.link.url);
  mobiNavClose();
};

const isLinkOut = computed(() => {
  return props?.link?.target?.includes('blank');
});
</script>
