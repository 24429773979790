import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { authInterceptor } from './auth-interceptor';

const getCmsUrl = (): string => {
  const url = '/';
  return url[url.length - 1] === '/' ? url.substring(0, url.length - 1) : url;
};
export const baseCmsUrl = getCmsUrl();

const normalizeUrl = (url: string): string => {
  return url[0] === '/' ? url.substring(1) : url;
};

const buildApiUrl = (url: string): string => {
  return `${baseCmsUrl}/${normalizeUrl(url)}`;
};

// Add all custom interceptors
axios.interceptors.response.use(undefined, authInterceptor);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiResponse<T = any, D = any> = AxiosResponse<T, D>;

/** The base configuration that **all** requests will use. */
const baseAxiosConfig: AxiosRequestConfig = {};

/**
 * Send a GET request to a specified `url`.
 *
 * @param url URL that request will be sent sent.
 * @param config Optional request configuration.
 * @returns Response from request.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const get = <Response = any, Config = any>(
  url: string,
  config?: AxiosRequestConfig<Config>
) =>
  axios.get<Response, ApiResponse<Response>>(buildApiUrl(url), {
    ...baseAxiosConfig,
    ...(config ?? {})
  });

/**
 * Send a POST request to a specified `url`.
 *
 * @param url URL that request will be sent sent.
 * @param body Body of data that will be sent with request.
 * @param config Optional request configuration.
 * @returns Response from request.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = <Response = any, Body = any, Config = any>(
  url: string,
  body: Body,
  config?: AxiosRequestConfig<Config>
) =>
  axios.post<Response, ApiResponse<Response>, Body>(url, body, {
    ...baseAxiosConfig,
    ...(config ?? {})
  });

/**
 * Send a PUT request to a specified `url`.
 *
 * @param url URL that request will be sent sent.
 * @param body Body of data that will be sent with request.
 * @param config Optional request configuration.
 * @returns Response from request.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const put = <Response = any, Body = any, Config = any>(
  url: string,
  body: Body,
  config?: AxiosRequestConfig<Config>
) =>
  axios.put<Response, ApiResponse<Response>, Body>(url, body, {
    ...baseAxiosConfig,
    ...(config ?? {})
  });

/**
 * Send a DELETE request to a specified `url`.
 *
 * @param url URL that request will be sent sent.
 * @param config Optional request configuration.
 * @returns Response from request.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const del = <Response = any, Config = any>(
  url: string,
  config?: AxiosRequestConfig<Config>
) => axios.delete<Response, ApiResponse<Response>>(url, config);

export default {
  get,
  post,
  put,
  del
};
