/**
 * The temporary cookie that is used for content retrieval in the client app.
 *
 * @remarks
 * When the initial request to the site passes through the custom middleware, this cookie can be populated.
 * Its value is simply the content id of the page. This can be used to retrieve content by id.
 * The purpose of this is to hook into Umbraco's default `IPublishedRequest` pipeline without requiring MVC or Razor templates.
 * This request pipeline is what allows content to be accessed by content id, `umbracoUrlAlias`, etc.
 * It is better to use this existing functionality than to try and recreate all of it in the client application.
 */
export const SQL_TEMPORARY_CONTENT_COOKIE = 'sql_tcid';
