<template>
  <Transition name="modal-toggle">
    <div class="modal" v-if="modalState.isOpen">
      <div class="body open">
        <div class="header">
          <h2 v-if="modalState.title != null">{{ modalState.title }}</h2>
          <button @click.prevent="close()" class="close">
            <i class="fa-solid fa-rectangle-xmark"></i>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="content">
          <component
            :is="modalState.view"
            v-bind="modalState.viewProps"
          ></component>
        </div>
        <div class="footer actions split-link">
          <SpinnerButton
            v-for="(action, $index) in computedModalActions"
            :key="$index"
            class="btn"
            :class="{
              link: action.type === 'link',
              c1: action.color === 'error',
              c2: action.color === 'success',
              c3: action.color == null || action.color === 'info',
              outline: action.outline
            }"
            :loading="action.loading"
            :submit-disabled="action.loading"
            :button-text="action.label"
            @click.prevent="action.callback?.(action, modalState)"
          ></SpinnerButton>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import SpinnerButton from '@/components/FormElements/SpinnerButton.vue';
import useModal from '@/stores/modal';
import { computed, onMounted, onUnmounted } from 'vue';

const modal = useModal();
const modalState = modal.state;
const computedModalActions = computed(() => {
  return (modalState.actions ?? []).map(action => ({
    ...action,
    loading:
      typeof action.loading === 'function' || action instanceof Function
        ? (action.loading as Function)(action, modalState)
        : action.loading ?? false
  }));
});

const close = () => modal.close();
const processKeyboard = (e: KeyboardEvent) => {
  if (e.key === 'Escape') close();
};
onMounted(() => document.addEventListener('keydown', processKeyboard));
onUnmounted(() => document.removeEventListener('keydown', processKeyboard));
</script>

<style lang="scss" scoped>
.modal-toggle-enter-active,
.modal-toggle-leave-active,
.body.open {
  transition: all 250ms ease-in-out;
}

.modal-toggle-enter-from,
.modal-toggle-leave-to {
  background-color: transparent;
}

.modal-toggle-leave-to .body.open {
  transform: translate(-50%, -50%) scale(0);
  animation-name: modal-open;
  transition-duration: 150ms;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
}
.modal-toggle-leave-active {
  transition-duration: 150ms;
}
</style>
