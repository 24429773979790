// * NOTE: Window functions won't work in templates.
// * Either create a computed to use in the template or
// * create a function that references the window function.
// * See note for translate function in this file.

// #region GLOBAL IMPORTS
import { nextTick, type Ref } from 'vue';
// #endregion GLOBAL IMPORTS

// #region SCROLL TO TOP
window.scrollToTop = async (
  behavior?: ScrollBehavior,
  position: Omit<ScrollToOptions, 'behavior'> = { top: 0, left: 0 }
): Promise<void> => {
  const scrollBehavior = behavior ?? 'smooth';
  const top = position.top ?? 0;
  const left = position.left ?? 0;
  await nextTick();
  window.scrollTo({
    behavior: scrollBehavior,
    top,
    left
  });
};
// #endregion SCROLL TO TOP

// #region SCROLL INTO VIEW
window.scrollIntoView = (
  el: Element | Ref<Element> | null,
  behavior: ScrollBehavior = 'smooth',
  block: ScrollLogicalPosition = 'start'
): void => {
  if (el instanceof Element) {
    el.scrollIntoView({ behavior, block });
  } else if (el && 'value' in el && el.value instanceof Element) {
    el.value.scrollIntoView({ behavior, block });
  }
};
// #endregion SCROLL INTO VIEW

// #region SCROLL TO FIRST ERROR
window.scrollToFirstError = (): void => {
  const errorFields = document.getElementsByClassName('warning');
  if (!errorFields.length) return;
  const firstField = errorFields[0];
  window.scrollIntoView(firstField, 'smooth', 'start');
};
// #endregion SCROLL TO FIRST ERROR

// #region TRUNCATE TEXT
window.truncateString = (input: string, maxLength: number): string => {
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + '...';
  } else {
    return input;
  }
};
// #endregion TRUNCATE TEXT

// #region NUMBER FORMATTING UTILITIES
window.formatAsUSD = (amount: number): string => {
  if (isNaN(amount)) {
    return '$0.00';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(amount);
};
// #endregion NUMBER FORMATTING UTILITIES

// #region URL PARAMS
window.getUrlParamValue = (key: string): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
};
window.deleteUrlParam = (key: string): void => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete(key);
  if (params.size > 0) {
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}?${params.toString()}`
    );
  } else {
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}`
    );
  }
};
// #endregion URL PARAMS

// #region BASE64 STUFF
window.base64Encode = <T>(data: T): string => {
  if (typeof data === 'string' && data.length > 0) {
    return btoa(data);
  } else if (
    typeof data === 'boolean' ||
    typeof data === 'number' ||
    typeof data === 'function'
  ) {
    return window.base64Encode(data.toString());
  } else if (data != null) {
    return window.base64Encode(JSON.stringify(data));
  }
  return '';
};

window.base64Decode = (base64: string): string | undefined => {
  if (base64.length <= 0) return undefined;
  return atob(base64);
};

window.base64DecodeJson = <T>(base64: string): T | undefined => {
  const decoded = window.base64Decode(base64);
  if (decoded == null) return undefined;
  try {
    return JSON.parse(decoded) as T;
  } catch {
    return undefined;
  }
};
// #endregion BASE64 STUFF

// #region DEEP COPY
window.deepCopy = <T>(source: T): T => {
  return JSON.parse(JSON.stringify(source));
};
// #endregion DEEP COPY

// #region CHECK IF VALUE EXISTS
window.exists = (value: any): boolean => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string') return value.trim() !== '';
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'object') return Object.keys(value).length > 0;
  // \/ This covers booleans and numbers.
  return true;
};
// #endregion CHECK IF VALUE EXISTS

// #region TRANSLATE
// * NOTE: Window functions won't work in templates.
// * Import translate from '@/shared/formatters', it references this function.
import { type LocalizableString, Lang } from '@/types/form-fields-v2';
window.translate = (
  item: LocalizableString,
  lang: Lang | undefined
): string => {
  if (item) {
    if (typeof lang !== 'string') lang = Lang.en;
    if (typeof item === 'object' && item !== null)
      return item[lang!] || item[Lang.en] || 'error';
    else if (typeof item === 'string' && item.length) return item;
    else return 'error';
  } else return '';
};
// #endregion TRANSLATE
