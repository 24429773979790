import apiClient from '@/shared/api-client';
import { SQL_TEMPORARY_CONTENT_COOKIE } from '@/shared/constants-cookies';
import { getCookie } from '@/shared/cookie-helper';
import { useContentCache } from '@/stores/content-cache';
import type { PageData } from '@/types';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import {
  deleteContentCookie,
  isBackofficePreview,
  isWebsitePreview
} from '../helpers';

/**
 * Navigation guard that will automatically retrieve content for the current route.
 */
export const contentFetchGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  // Check if content guard is supposed to be ignored
  if (to.meta?.ignoreFetchContent) {
    return next();
  }

  const contentCache = useContentCache();

  // Determine current route
  const isSubComponent = to.meta?.subComponent;
  const route =
    isSubComponent && to.meta.contentPath
      ? String(to.meta.contentPath)
      : to.path;

  // Define initial endpoint and parameters that will be used to retrieve content
  let contentEndpoint = '/';
  const contentEndpointParams = new URLSearchParams();
  contentEndpointParams.set('route', route); // By default, get all content by its route

  // If content id cookie is present, use that for retrieving content
  let contentId: number | undefined = undefined;
  const contentIdCookie = getCookie(SQL_TEMPORARY_CONTENT_COOKIE);
  if (contentIdCookie != null) {
    contentEndpointParams.delete('route');
    contentId = parseInt(contentIdCookie);
    deleteContentCookie();
  } else {
    // If no cookie was present, check if the URL contains an id
    // Try to determine if the current route is using an Umbraco content node id
    const regex = /^\/(\d+)/; // Look at start of string with "^". Requires a "/" via "\/". Find the digits.
    const match = route.match(regex);
    contentId = match != null ? parseInt(match[1]) : undefined;
  }

  // If a content id was found, update the content endpoint accordingly
  if (contentId != null && !isNaN(contentId)) {
    contentEndpointParams.delete('route');
    contentEndpoint = '/' + contentId;
  }

  // If Umbraco preview cookies are present, add the preview parameter
  if (isBackofficePreview() || isWebsitePreview()) {
    contentEndpointParams.set('preview', 'true');
  }

  // If there is no page data for the path being navigated to, retrieve via API request
  let pageData = contentCache.getCachedData(route);
  if (pageData == null) {
    try {
      const queryString =
        contentEndpointParams.size > 0
          ? '?' + contentEndpointParams.toString()
          : '';
      pageData = (
        await apiClient.get<PageData>(
          '/api/content' + contentEndpoint + queryString
        )
      ).data;
      contentCache.addCachedData(pageData);
    } catch (err) {
      console.error('Error fetching data from API:', err);
      return next();
    }
  }

  contentCache.setPageData(pageData); // Cache the page data

  return next();
};
