import { useContentCache } from '@/stores/content-cache';
import type { Media } from '@/types';
import { storeToRefs } from 'pinia';

export const metadataHook = () => {
  const contentCache = useContentCache();
  const { pageData } = storeToRefs(contentCache);
  if (pageData.value == null) return;

  const { metadata, settings } = pageData.value;

  // Build page name and site name from page data and then update the "document.title" to use it
  const siteName = settings.siteName ?? import.meta.env.VITE_SITE_NAME_FALLBACK;
  const pageTitle = settings.page.pageName ?? metadata.name;
  document.title =
    pageTitle !== siteName ? `${pageTitle} - ${siteName}` : siteName;

  const pageDescription = settings.page.pageDescription ?? '';
  appendMetaTag('description', pageDescription);

  appendMetaTag('og:url', window.location.href);
  appendMetaTag('og:type', 'website');
  appendMetaTag('og:title', document.title);
  appendMetaTag('og:description', pageDescription);
  appendMetaTag('og:image', getImageUrl(settings.page.socialImage));

  appendMetaTag('twitter:card', 'summary_large_image');
  appendMetaTag('twitter:site', '@SonoraQuestLabs');
  appendMetaTag('twitter:title', document.title);
  appendMetaTag('twitter:description', pageDescription);
  appendMetaTag('twitter:image', getImageUrl(settings.page.twitterImage));
};

const appendMetaTag = (property: string, content: string) => {
  const head = document.head;

  const setAttributes = (element: HTMLMetaElement) => {
    element.setAttribute('property', property);
    element.setAttribute('content', content);
  };

  const metaTags = head.querySelectorAll<HTMLMetaElement>(
    `meta[property="${property}"]`
  );
  if (metaTags.length > 0) {
    [...metaTags].splice(1).forEach(e => e.remove());
    setAttributes(metaTags[0]);
  } else {
    const meta = document.createElement('meta');
    setAttributes(meta);
    head.append(meta);
  }
};

const getImageUrl = (media?: Media) => {
  return media?.url != null
    ? window.location.protocol + '//' + window.location.host + media?.url
    : '';
};
