import {
  authGuard,
  contentFetchGuard,
  metadataHook,
  profileGuard,
  trackHistory,
  websitePreviewHook
} from './guards';
import PageTemplateRenderer from '@/components/Renderers/PageTemplateRenderer.vue';
import { ROUTES } from '@/shared/constants';
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw
} from 'vue-router';

export default () => {
  const routes: RouteRecordRaw[] = [
    {
      path: ROUTES.NOT_FOUND,
      component: () =>
        import('@/components/Templates/Internal/NotFoundTemplate.vue'),
      meta: {
        ignoreFetchContent: true
      }
    },
    {
      path: ROUTES.UNAUTHORIZED,
      component: () =>
        import('@/components/Templates/Internal/UnauthorizedTemplate.vue'),
      meta: {
        ignoreFetchContent: true
      }
    },
    {
      path: '/:pathMatch(.*)',
      component: PageTemplateRenderer
    }
  ];

  const router = createRouter({
    history: createWebHistory('/'),
    routes
  });

  // Apply all guards that need to execute before each navigation
  router.beforeEach(trackHistory); // Track the last URL visited during every navigation event
  router.beforeEach(profileGuard); // Check login status and get profile data

  // Apply all guards that need to execute before a navigation is about to be resolved
  router.beforeResolve(contentFetchGuard); // Get page data for route being navigated to
  router.beforeResolve(authGuard); // Apply authorization protections

  // Apply all hooks that execute after each navigation
  router.afterEach(websitePreviewHook); // Override default Umbraco Preview badge functionality
  router.afterEach(metadataHook); // Update <head> and/or <meta> tags

  // Scroll to top of page if a hash is not present
  router.afterEach(to => {
    if (!to.hash) window.scrollToTop();
  });

  return router;
};
