<template>
  <transition name="fade" mode="out-in">
    <div class="toast" v-if="visible">
      <div
        v-html="toastMessage.message"
        class="content"
        :class="{ warning: toastMessage.isWarning }"
      ></div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
const visible = ref(false);

//#region PROFILE STUFF
import { useProfileStore } from '@/stores/profile';
const profileStore = useProfileStore();
const { toastMessage } = storeToRefs(profileStore);
//#endregion PROFILE STUFF

onMounted(() => {
  visible.value = true;
  setTimeout(
    () => {
      visible.value = false;
      setTimeout(
        () => {
          profileStore.toastMessageHide();
        },
        toastMessage.value.quick ? 500 : 1000
      ); // 1 second fade out
    },
    toastMessage.value.quick ? 3000 : 7000
  ); // 2 second fade in, 5 seconds on screen
});
</script>

<style lang="scss">
.toast {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 199999;
  .content {
    background-color: var(--color2);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 300px;
    color: white;
    padding: 20px;
    p,
    h1,
    h2,
    h3,
    h4,
    li {
      color: white;
    }
    &.warning {
      background-color: var(--warning);
      color: white !important; // "!important" is required to override default styling of ".warning"
    }
  }
}

.fade-enter-active {
  transition: opacity 2000ms;
}

.fade-leave-active {
  transition: opacity 1000ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>

