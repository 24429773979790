<template>
  <div
    v-if="globalEnabled"
    v-cloak
    id="important"
    :class="{ active: showMessage }"
  >
    <div @click="togAlert()" class="alert">
      <span v-cloak>{{ globalCallToAction }}</span>
      <i class="fa-solid fa-caret-down"></i>
    </div>
    <div class="message">
      <div class="box box1000">
        <div
          v-if="globalMessageIcon"
          v-html="globalMessageIcon"
          class="icon"
        ></div>
        <div v-html="globalMessage" v-routerify-anchors class="text"></div>
      </div>
    </div>
  </div>
  <header id="header" :class="{ searching: searching }">
    <div class="top">
      <div class="box box1600">
        <router-link id="logo" to="/">
          <img src="/assets/logo.svg" alt="Sonora Quest Laboratory" />
        </router-link>
        <a @click="mobiNavOpen()" href="javascript:;" class="mobi-tog">
          <i class="fa-solid fa-bars"></i>
        </a>
        <ul class="right">
          <li v-if="isLoggedIn" class="logout">
            <a href="#" @click.prevent="logout">
              <i class="fa-solid fa-user"></i>
              <span>Logout</span>
            </a>
          </li>
          <li
            v-else
            v-click-outside="closeLogin"
            class="login explore"
            :class="{ active: showLogin }"
          >
            <a @click.prevent="togLogin()" href="#">
              <i class="fa-solid fa-user icon"></i>
              <span>Login</span>
              <i class="fa-solid fa-caret-down tog"></i>
            </a>
            <ul>
              <li>
                <a :href="computePatientLoginUrl">
                  <span>Patient Login/Create Account</span>
                </a>
              </li>
              <li>
                <a :href="computeProviderLoginUrl">
                  <span>Provider Login/Create Account</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="hr">&nbsp;</li>
          <li
            v-click-outside="closeExplore"
            class="explore"
            :class="{ active: showExplore }"
          >
            <a @click.prevent="togExplore()" href="#">
              <span>Explore Our Sites</span>
              <i class="fa-solid fa-caret-down"></i>
            </a>
            <ul>
              <li>
                <router-link to="/patients/" @click.prevent="togExplore()">
                  <span>Patients</span>
                </router-link>
              </li>
              <li>
                <router-link to="/providers/" @click.prevent="togExplore()">
                  <span>Providers</span>
                </router-link>
              </li>
              <li>
                <router-link to="/" @click.prevent="togExplore()">
                  <span>Corporate</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="search">
            <a @click.prevent="searchTog()" href="javascript:;">
              <i class="fa-solid fa-magnifying-glass"></i>
              <span class="visually-hidden">Search</span>
            </a>
          </li>
        </ul>
        <form id="site-search">
          <label for="search-field" class="visually-hidden">Site Search</label>
          <div class="ui">
            <input
              type="text"
              name="siteSearch"
              id="search-field"
              aria-label="Enter Search Criteria"
              placeholder="Enter Search Criteria"
              autocomplete="off"
              v-model="searchTerm"
              ref="searchInput"
            />
            <button
              @click.prevent="search()"
              type="submit"
              name="search"
              aria-label="Search Submit Button"
              class="btn"
            >
              <i class="fa-solid fa-magnifying-glass"></i>
              <span class="visually-hidden">Site Search</span>
            </button>
          </div>
          <a @click.prevent="searchTog()" href="#" class="close">
            <i aria-hidden="true" class="fa-solid fa-xmark"></i>
            <span class="visually-hidden">Close Search</span>
          </a>
        </form>
      </div>
    </div>
    <nav id="main-nav">
      <div class="box box1600">
        <ul class="nav">
          <li v-for="(link, i) in navigationItems" :key="`main${i}`">
            <NavLink :link="link" />
            <ul v-if="hasChildren(link)">
              <li
                v-for="(child, i) in link.children"
                :key="`${link.name}-sub${i}`"
              >
                <NavLink :link="child" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <transition name="fade-scale">
    <nav v-if="mobiNavActive" id="mobi-nav">
      <div class="mobi-top">
        <div class="box box1600">
          <router-link @click="mobiNavClose()" to="/" id="logo">
            <img src="/assets/logo.svg" alt="Sonora Quest Laboratory" />
          </router-link>
          <a @click="mobiNavClose()" href="javascript:;" class="close">
            <i class="fa-solid fa-xmark"></i>
          </a>
        </div>
      </div>
      <ul class="mobi-nav">
        <li v-if="isLoggedIn" class="logout">
          <a href="#" @click.prevent="logout">
            <i class="fa-solid fa-user"></i>
            <span>Logout</span>
          </a>
        </li>
        <li v-if="patientOutOfBounds" class="login">
          <router-link
            to="/patients/dashboard/"
            @click.prevent="mobiNavClose()"
          >
            <i class="fa-solid fa-house"></i>
            <span>Return to Patient Dashboard</span>
          </router-link>
        </li>
        <li v-if="providerOutOfBounds" class="login">
          <router-link
            to="/providers/dashboard/"
            @click.prevent="mobiNavClose()"
          >
            <i class="fa-solid fa-house"></i>
            <span>Return to Provider Dashboard</span>
          </router-link>
        </li>
        <li v-if="!isLoggedIn" class="login">
          <a :href="computePatientLoginUrl">
            <i class="fa-solid fa-user"></i>
            <span>Patient Login/Create Account</span>
          </a>
        </li>
        <li v-if="!isLoggedIn" class="login">
          <a :href="computeProviderLoginUrl">
            <i class="fa-solid fa-user"></i>
            <span>Provider Login/Create Account</span>
          </a>
        </li>
        <li v-for="(link, i) in navigationItems" :key="`main${i}`">
          <NavLink @mobiNavClose="mobiNavClose" :link="link" />
          <ul v-if="hasChildren(link)">
            <li
              v-for="(child, i) in link.children"
              :key="`${link.name}-sub${i}`"
            >
              <NavLink @mobiNavClose="mobiNavClose" :link="child" />
            </li>
          </ul>
        </li>
      </ul>
      <form id="mobi-site-search">
        <label for="search-field" class="visually-hidden">Site Search</label>
        <div class="ui">
          <input
            type="text"
            name="siteSearch"
            id="search-field"
            aria-label="Enter Search Criteria"
            placeholder="Enter Search Criteria"
            autocomplete="off"
            v-model="searchTerm"
            ref="searchInput"
          />
          <button
            @click.prevent="search()"
            type="submit"
            name="search"
            aria-label="Search Submit Button"
            class="btn"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            <span class="visually-hidden">Site Search</span>
          </button>
        </div>
      </form>
      <ul class="mobi-links">
        <li>
          <router-link to="/" @click.prevent="mobiNavClose()">
            <span>Corporate Website</span>
          </router-link>
        </li>
        <li>
          <router-link to="/test-directory/" @click.prevent="mobiNavClose()">
            <span>Test Directory</span>
          </router-link>
        </li>
        <li>
          <router-link to="/contact-us/" @click="mobiNavClose()">
            <span>Contact Us</span>
          </router-link>
        </li>
        <li>
          <router-link to="/about/" @click="mobiNavClose()">
            <span>About Us</span>
          </router-link>
        </li>
      </ul>
      <ul class="mobi-socs">
        <li v-for="(soc, i) in pageData?.settings.socialLinks" :key="`soc${i}`">
          <a :href="soc.link" target="blank">
            <span v-html="soc.icon"></span>
            <span>{{ soc.name }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </transition>
</template>

<script setup lang="ts">
// #region GENERAL IMPORTS
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import NavLink from '@/components/AppHeader/NavLink.vue';
import type { PageData } from '@/types/content';
import { useRoute } from 'vue-router';
const route = useRoute();
const props = defineProps<{
  pageData?: PageData | null;
}>();
// #endregion GENERAL IMPORTS

// #region PROFILE STUFF
import { useProfileStore } from '@/stores/profile';
const profileStore = useProfileStore();
const { isLoggedIn, logout } = profileStore;
const { profile } = storeToRefs(profileStore);
// #endregion PROFILE STUFF

const patientOutOfBounds = computed(() => {
  const patientUser = profile.value?.memberType === 'patient';
  const patientLocation = route.path.startsWith('/patients/');
  return isLoggedIn && patientUser && !patientLocation;
});

const providerOutOfBounds = computed(() => {
  const providerUser = profile.value?.memberType === 'provider';
  const providerLocation = route.path.startsWith('/providers/');
  return isLoggedIn && providerUser && !providerLocation;
});

// #region GLOBAL MESSAGE STUFF
const globalEnabled = computed(() => props.pageData?.settings.globalCtaEnabled);
const globalCallToAction = computed(() => props.pageData?.settings.globalCta);
const globalMessage = computed(() => props.pageData?.settings.globalMessage);
const globalMessageIcon = computed(
  () => props.pageData?.settings.globalMessageIcon
);
const showMessage = ref<boolean>(false);
const togAlert = (): void => {
  if (showMessage.value) {
    showMessage.value = false;
    localStorage.setItem(
      'globalMessage',
      JSON.stringify({ html: globalMessage.value })
    );
  } else {
    showMessage.value = true;
    localStorage.removeItem('globalMessage');
  }
};
interface GlobalMessage {
  html: string;
}
onMounted(() => {
  const storedMessageString = localStorage.getItem('globalMessage');
  const storedMessage: GlobalMessage | null = storedMessageString
    ? (JSON.parse(storedMessageString) as GlobalMessage)
    : null;
  if (storedMessage && storedMessage.html !== globalMessage.value) {
    showMessage.value = true;
  }
  if (!storedMessage) {
    showMessage.value = true;
  }
});
// #endregion GLOBAL MESSAGE STUFF

// #region EXPLORE STUFF
const showExplore = ref<boolean>(false);
const togExplore = (): void => {
  showExplore.value = !showExplore.value;
};
const closeExplore = (): void => {
  showExplore.value = false;
};
// #endregion EXPLORE STUFF

// #region LOGIN STUFF
const showLogin = ref<boolean>(false);
const togLogin = (): void => {
  showLogin.value = !showLogin.value;
};
const closeLogin = (): void => {
  showLogin.value = false;
};
// #endregion LOGIN STUFF

// #region NAV STUFF
const navigationItems = computed(() => props.pageData?.navigation.header);
interface Route {
  children?: any[];
}
const computePatientLoginUrl = computed(() =>
  profileStore.generateLoginUrl('patient', '/patients/dashboard/')
);
const computeProviderLoginUrl = computed(() =>
  profileStore.generateLoginUrl('provider', '/providers/dashboard/')
);
const mobiNavActive = ref<boolean>(false);
const mobiNavOpen = (): void => {
  mobiNavActive.value = true;
};
const mobiNavClose = (): void => {
  mobiNavActive.value = false;
};
let hasChildren = (route: Route) => {
  return Array.isArray(route.children) && route.children.length;
};
// #endregion NAV STUFF

// #region SEARCH STUFF
import type { TextFieldType } from '@/types/form-fields-v2';
import { useRouter } from 'vue-router';
const searching = ref<boolean>(false);
const searchInput = ref<HTMLInputElement>();
const searchTog = (): void => {
  searching.value = !searching.value;
  if (searchInput.value != null && searching.value) {
    searchInput.value.focus();
  }
  mobiNavActive.value = false;
};
const searchTerm = ref<string>('');
const router = useRouter();
const search = () => {
  const searchResultsLink = props.pageData?.settings?.searchResultsUrl;
  if (searchResultsLink == null) {
    profileStore.toastMessageShow('Unable to search for content');
    console.error(
      "Cannot perform search because no 'searchResultsUrl' property is set under 'Site Settings'"
    );
    return;
  }

  router
    .push({
      path: searchResultsLink.url,
      query: {
        ...router.currentRoute.value.query,
        term: searchTerm.value
      }
    })
    .then(() => {
      searchTerm.value = '';
      searchTog();
    })
    .catch(err => {
      console.error('Could not navigate to search results page');
      console.error(err);
    });
};
// #endregion SEARCH STUFF

// #region DYNAMIC BODY SIZE
onMounted(() => {
  const important = document.getElementById('important');
  const header = document.getElementById('header');
  const updateCustomProperties = () => {
    if (important) {
      document.documentElement.style.setProperty(
        '--important_height',
        `${important.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--important_height', `0px`);
    }
    if (header) {
      document.documentElement.style.setProperty(
        '--header_height',
        `${header.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--header_height', `0px`);
    }
  };
  updateCustomProperties();
  const resizeObserver = new ResizeObserver(updateCustomProperties);
  if (important) resizeObserver.observe(important);
  if (header) resizeObserver.observe(header);
  onUnmounted(() => {
    if (important) resizeObserver.unobserve(important);
    if (header) resizeObserver.unobserve(header);
    resizeObserver.disconnect();
  });
});
// #endregion DYNAMIC BODY SIZE
</script>
