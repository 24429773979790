<template>
  <ToastMessage v-if="toastMessage.show"></ToastMessage>
  <ModalTemplate></ModalTemplate>
  <AppHeader v-if="showHeader" :pageData="navigationPageData" />
  <div :id="gimmeMainId" :class="gimmeClass" :style="gimmeStyle">
    <!-- Where Vue Router dynamically injects components based on the route -->
    <router-view />
  </div>
  <AppFooter v-if="showFooter" :pageData="navigationPageData" />
  <Chatbot v-if="pageData != null"></Chatbot>
</template>

<script setup lang="ts">
import AppFooter from '@/components/AppFooter';
import AppHeader from '@/components/AppHeader';
import ToastMessage from '@/components/General/ToastMessage.vue';
import ModalTemplate from '@/components/General/Modals/ModalTemplate.vue';
import Chatbot from '@/components/Chatbot/_chatbot.vue';
import contentApi from '@/shared/api-client/api/content';
import type { PageData } from '@/types';
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useContentCache } from '@/stores/content-cache';
const contentCache = useContentCache();
const { pageData } = storeToRefs(contentCache);

//#region NAVIGATION
/**
 * Page data that is used for the header and footer.
 * The reason this is different than the regular page data is to support header/footer on pages with no real page data.
 */
const navigationPageData = ref<PageData>();
/**
 * Refresh the page data used for navigation.
 *
 * @param data Updated page data to use.
 * @param useFallback If true, fallback page data will be used if the incoming page data is null.
 */
const refreshNavigation = (
  data: PageData | undefined | null,
  useFallback: boolean
) => {
  // Set page data that will be used for the navigation (header and footer)
  // This will usually be the same page data as the current page itself
  // However, this can potentially vary if the current page doesn't have real content and fallback is enabled for this function
  navigationPageData.value = data ?? undefined;

  // If incoming page data is null and we have been instructed to use fallback content, do so now
  if (data == null && useFallback) {
    contentApi
      .getByRoute('/') // Use homepage for fallback navigation content
      .then(res => refreshNavigation(res.data, false)) // DO NOT set fallback here or else we will cause a stack overflow)
      .catch(err => console.error('Failed to retrieve fallback content', err));
  }
};
// Create watcher that will update the page data used for navigation
// It is safe to watch the usual page data because in the majority of cases, the navigation will use the same page data as the page itself
watch(pageData, () => refreshNavigation(pageData.value, false));
refreshNavigation(pageData.value, true); // Perform initial update of data. Enable fallback content for the initial load.
//#endregion

//#region PROFILE STUFF
import { useProfileStore } from '@/stores/profile';
const profileStore = useProfileStore();
const { toastMessage } = storeToRefs(profileStore);
//#endregion PROFILE STUFF

//#region HEADER AND FOOTER VARS
onMounted(() => {
  const header = document.getElementById('header');
  const footer = document.getElementById('footer');
  const headerHeight = ref('');
  const footerHeight = ref('');
  const updateCustomProperties = () => {
    if (header && footer) {
      const newHeaderHeight = `${header.clientHeight}px`;
      const newFooterHeight = `${footer.clientHeight}px`;
      document.documentElement.style.setProperty(
        '--header_height',
        newHeaderHeight
      );
      document.documentElement.style.setProperty(
        '--footer_height',
        newFooterHeight
      );
      headerHeight.value = newHeaderHeight;
      footerHeight.value = newFooterHeight;
    }
  };
  updateCustomProperties();
  const resizeObserver = new ResizeObserver(updateCustomProperties);
  if (header) resizeObserver.observe(header);
  if (footer) resizeObserver.observe(footer);
  onUnmounted(() => {
    if (header) resizeObserver.unobserve(header);
    if (footer) resizeObserver.unobserve(footer);
    resizeObserver.disconnect();
  });
});
//#endregion HEADER AND FOOTER VARS

const isLandingPage = computed(() => {
  return pageData.value?.alias === 'landingPage';
});

const isCovidPortal = computed(() => {
  return pageData.value?.alias === 'covidPortal';
});

const gimmeMainId = computed(() => {
  if (isLandingPage.value) return 'landing';
  if (isCovidPortal.value) return 'covid-portal';
  else return 'main';
});

const gimmeStyle = computed(() => {
  return isLandingPage.value
    ? `background-image: url(${pageData.value!.content?.backgroundImage?.url})`
    : '';
});

const gimmeClass = computed(() => {
  let classes = '';
  if (pageData.value == null) return classes;

  if (pageData.value.content.backgroundStyle == 'Stretch')
    classes += 'stretch ';
  if (pageData.value.content.backgroundStyle == 'Tile') classes += 'tile ';
  if (pageData.value.content.backgroundAlignment == 'Left / Top')
    classes += 'left-top ';
  if (pageData.value.content.backgroundAlignment == 'Center / Top')
    classes += 'center-top ';
  if (pageData.value.content.backgroundAlignment == 'Right / Top')
    classes += 'right-top ';
  if (pageData.value.content.backgroundAlignment == 'Left / Center')
    classes += 'left-center ';
  if (pageData.value.content.backgroundAlignment == 'Center / Center')
    classes += 'center-center ';
  if (pageData.value.content.backgroundAlignment == 'Right / Center')
    classes += 'right-center ';
  if (pageData.value.content.backgroundAlignment == 'Left / Bottom')
    classes += 'left-bottom ';
  if (pageData.value.content.backgroundAlignment == 'Center / Bottom')
    classes += 'center-bottom ';
  if (pageData.value.content.backgroundAlignment == 'Right / Bottom')
    classes += 'right-bottom ';
  return classes;
});

const showHeader = computed(() => {
  return (
    pageData.value == null || (!isLandingPage.value && !isCovidPortal.value)
  );
});

const showFooter = computed(() => {
  return (
    pageData.value == null || (!isLandingPage.value && !isCovidPortal.value)
  );
});
</script>
